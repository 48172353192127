import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { upperCaseLetter } from '../../utils/bibli';
import {
  PreviewScheduleContainer,
  ScheduleContainer,
  RowDateAvaliable,
  DateAvaliable,
  ContainerButton,
  TitleButton,
  SeeMoreButton,
} from './styled';
import DrButton from '../dr-button';
import DrIconFont from '../dr-icon-font';

const DrShowSchedulesSpecialties = ({
  specialty,
  styleGrid,
  color,
  screen,
}) => {
  const [customWidth, setCustomWidth] = useState('');
  const [specialities, setSpecialities] = useState(specialty.horarios);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    setCustomWidth(windowSize.width <= 380 ? '55px' : '75px');
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setSpecialities(specialty.horarios);
  }, [specialty]);
  const DateComponent = (dia) => {
    const format = 'YYYY/MM/DD';
    const weekDayRef = upperCaseLetter(
      moment(dia, format).local().format('ddd'),
      '-',
      '-'
    );
    const todayFormatted = moment().local().format(format);
    const tomorrowFormatted = moment().add(1, 'days').local().format(format);
    const isToday = upperCaseLetter(todayFormatted, '-', '-');
    const isTomorrow = upperCaseLetter(tomorrowFormatted, '-', '-');

    if (dia === isToday) {
      return 'Hoje';
    }
    if (dia === isTomorrow) {
      return 'Amanhã';
    }
    return weekDayRef;
  };
  return (
    <PreviewScheduleContainer>
      <ScheduleContainer direction={styleGrid} screen={screen}>
        {specialities &&
          specialities.map((especialidade) => (
            <RowDateAvaliable
              key={specialities.id}
              direction={styleGrid}
              screen={screen}
            >
              <DateAvaliable>
                {DateComponent(especialidade.dt_agenda)}
              </DateAvaliable>
              {especialidade.vagas &&
                especialidade.vagas.map((vaga) => (
                  <DrButton
                    key={specialty.idespecialidade}
                    type="button"
                    title={moment(vaga.dt_agenda).local().format('HH:mm')}
                    color="#60bb46"
                    fontWeight="700"
                    customPadding="2px 10px"
                    customWidth={customWidth}
                  />
                ))}
            </RowDateAvaliable>
          ))}
      </ScheduleContainer>
      <ContainerButton color={color} screen={screen}>
        <TitleButton> Ver mais</TitleButton>
        <SeeMoreButton key={specialty.idespecialidade}>
          <DrIconFont
            color="rgb(63, 86, 165)"
            name="circle-arrow-right"
            fontSize="28px"
          />
        </SeeMoreButton>
      </ContainerButton>
    </PreviewScheduleContainer>
  );
};
export default DrShowSchedulesSpecialties;
