import styled from 'styled-components';

interface ContainerProps {
  direction?: string;
  color?: string;
  screen?: string;
}

export const PreviewScheduleContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Distribui os elementos ao longo da largura */
  align-items: center; /* Alinha os elementos verticalmente ao centro */
  width: 100%; /* Ajuste conforme necessário */
  padding: 3px; /* Ajuste conforme necessário */
`;

export const ScheduleContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) =>
    props.direction && props.direction === 'column' ? 'row' : 'column'};
  align-items: center;
  flex-grow: 1; /* Faz com que ocupe o espaço disponível */
  text-align: center;
  gap: 1%;
  margin-left: 10%;
  @media screen and (max-width: 990px) {
    margin-left: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '0px' : '7%'};
  }
`;

export const RowDateAvaliable = styled.div<ContainerProps>`
  padding: 5px 10px;
  color: black;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: center;
  text-align: ${(props) =>
    props.direction && props.direction === 'column' ? 'center' : 'start'};
  justify-content: space-between;
  min-width: ${(props) =>
    props.direction && props.direction === 'column' ? 'none' : '55%'};
  height: ${(props) =>
    props.direction && props.direction === 'column' ? '160px' : 'auto'};
  border-radius: 5px;

  @media screen and (max-width: 900px) {
    min-width: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '15%' : '95%'};
    padding: ${(props) =>
      props.screen && props.screen === 'selectSpecialties'
        ? '0px'
        : '5px 10px'};
    margin-left: 0%;
  }
`;

export const DateAvaliable = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: rgb(63, 86, 165);
`;
export const TimeAvaliable = styled.p`
  background-color: blue;
  border-radius: 5px;
  margin: 0;
`;

export const TitleButton = styled.h6`
  font-weight: 700;
  margin-bottom: 3px;
  color: rgb(63, 86, 165);
  @media screen and (max-width: 915px) {
    display: none;
  }
`;
export const SeeMoreButton = styled.div``;
export const ContainerButton = styled.div<ContainerProps>`
  min-width: 30px;
  display: flex;
  justify-content: flex-end; /* Alinha o componente no final da div */
  align-items: center;
  margin-left: auto; /* Empurra o componente para o final da div */
  flex-direction: column;
  color: ${(props) => (props.color ? props.color : 'black')};
  @media screen and (max-width: 415px) {
    display: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? 'none' : 'flex'};
  }
`;
